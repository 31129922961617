var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    class: _vm.props.classes,
    staticStyle: {
      "min-height": "2.8em",
      "max-width": "70em"
    },
    attrs: {
      "tabindex": _vm.props.tabIndex,
      "data-label": _vm.props.column
    }
  }, [_c('div', {
    staticClass: "default-cell"
  }, [_c('vnodes', {
    attrs: {
      "vnodes": _vm.props.content
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }