<template>
  <td
    :tabindex="props.tabIndex"
    :class="props.classes"
    :data-label="props.column"
    style="min-height: 2.8em; max-width: 70em"
  >
    <div class="default-cell"><vnodes :vnodes="props.content" /></div>
  </td>
</template>

<script>
export default {
  name: 'MyTableCell',
  props: ['props'],
  components: {
    vnodes: {
      functional: true,
      render: (h, ctx) =>
        typeof ctx.props.vnodes === 'object'
          ? ctx.props.vnodes
          : [ctx.props.vnodes]
    }
  }
}
</script>

<style scoped>
.default-cell {
  min-height: 2.8em;
  display: flex;
  align-items: center;

  /*word-wrap: break-word;*/
  line-height: normal;
  overflow-wrap: anywhere;
  /*
  display: block;
  text-overflow: ellipsis;
  max-width: 20em;

  */
}
</style>
